/* eslint-disable no-unused-vars */
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useEffect, useState } from 'react';
import { List, ListItem } from '@material-ui/core';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import HelpIcon from 'components/commons/HelpIcon';

function Adresse({
  classes,
  title,
  address,
  additionalAddress = null,
  city,
  cityLabel,
  zipCode,
  disabled,
  disabledCity = false,
  disabledZipCode = false,
  department,
  country,
  nationality,
  countryLabel,
  helpName,
}) {
  const { setFieldValue, values } = useFormikContext();
  const [curAddress, setCurrentAddress] = useState();
  const {
    // ready,
    // value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    // Update the keyword of the input element
    setFieldValue(name, value);
    setValue(value);

    if (name === address && !value) {
      setFieldValue(zipCode, '');
      setFieldValue(city, '');
    }
  };

  useEffect(() => {
    if (values[address] !== curAddress) {
      // eslint-disable-next-line no-lone-blocks
      {
        if (values[zipCode]) setFieldValue(zipCode, '');
        if (values[city]) setFieldValue(city, '');
      }
    }
  }, [curAddress, values[address]]);

  const getInfoOfAdress = (allAdressInfo, detailId) => {
    try {
      return allAdressInfo.filter((item) => item.types[0] === detailId)[0]
        .long_name;
    } catch (e) {
      return '';
    }
  };

  const handleSelect =
    ({ description }) =>
    async () => {
      setValue(description, false);
      clearSuggestions();

      try {
        const [geoCode] = await getGeocode({ address: description });

        const { address_components } = geoCode;
        console.log('address_components', address_components);
        const streetNumber = getInfoOfAdress(
          address_components,
          'street_number'
        );
        const route = getInfoOfAdress(address_components, 'route');

        const cityFound = getInfoOfAdress(address_components, 'locality');
        const departmentFound = getInfoOfAdress(
          address_components,
          'administrative_area_level_2'
        );
        const countryFound = getInfoOfAdress(address_components, 'country');
        const zipCodeFound = getInfoOfAdress(address_components, 'postal_code');

        const addressFound =
          streetNumber || route ? `${streetNumber} ${route}` : description;
        setFieldValue(address, addressFound);
        setCurrentAddress(addressFound);

        setFieldValue(zipCode, zipCodeFound || '');
        setFieldValue(city, cityFound || '');
        setFieldValue(country, countryFound || '');
        setFieldValue(department, departmentFound || '');
      } catch (error) {
        console.log('😱 Error: ', error);
      }
    };

  const renderSuggestions = () => (
    <div style={{ width: '100%' }}>
      <List>
        {data.map((suggestion) => {
          const {
            place_id,
            structured_formatting: { main_text, secondary_text },
          } = suggestion;
          return (
            <ListItem button key={place_id} onClick={handleSelect(suggestion)}>
              <strong>{main_text}</strong>&nbsp;<small>{secondary_text}</small>
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  useEffect(() => {
    if (values[country] === 'France') {
      setFieldValue(nationality, 'Française');
    }
  }, [values[country]]);

  return (
    <>
      {title && (
        <h2 className={classes.subTitle}>
          {title} {helpName && <HelpIcon helpName={helpName} />}
        </h2>
      )}
      {address && (
        <Field
          component={TextField}
          type="string"
          label="Adresse *"
          name={address}
          className={classes.nameTextField}
          variant="outlined"
          size="small"
          disabled={disabled}
          onChange={handleInput}
        />
      )}
      {additionalAddress && (
        <Field
          component={TextField}
          type="string"
          label="Complément d'adresse postale"
          name={additionalAddress}
          className={classes.nameTextField}
          autoComplete="off"
          variant="outlined"
          size="small"
          inputProps={{ style: { textTransform: 'capitalize' } }}
        />
      )}

      {status === 'OK' && renderSuggestions()}
      <div className={classes.townAndZipCode}>
        <Field
          component={TextField}
          type="string"
          label={cityLabel}
          name={city}
          className={`${classes.nameTextField} ${classes.town}`}
          variant="outlined"
          size="small"
          disabled={disabledCity}
          onChange={handleInput}
        />
        <Field
          component={TextField}
          type="int"
          label="Code postal *"
          name={zipCode}
          className={`${classes.nameTextField} ${classes.zipCode}`}
          variant="outlined"
          size="small"
          disabled={disabledZipCode}
        />
      </div>
      {department && (
        <Field
          component={TextField}
          type="string"
          label="Département *"
          name={department}
          className={classes.nameTextField}
          variant="outlined"
          size="small"
          disabled={disabled}
        />
      )}
      {country && (
        <Field
          component={TextField}
          type="string"
          label={countryLabel}
          name={country}
          className={classes.nameTextField}
          variant="outlined"
          size="small"
          disabled={disabled}
        />
      )}
      {nationality && (
        <Field
          component={TextField}
          type="string"
          label="Nationalité *"
          name={nationality}
          className={classes.nameTextField}
          autoComplete="off"
          variant="outlined"
          size="small"
        />
      )}
    </>
  );
}

export default Adresse;

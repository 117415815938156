import React, { useEffect, useRef, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import { fetchRCS } from 'helpers/rcsHelper';
import InputWithHelpIcon from 'components/commons/InputWithHelpIcon';
import HelpIcon from 'components/commons/HelpIcon';
import { CircularProgress } from '@material-ui/core';
import { SCI } from 'constants/companies';
import { getTitle } from 'helpers/generalHelpers';
import _ from 'lodash';
import { BBF_NOTARIE, YES } from 'constants/usersContants';
import useStyles from '../style';
import Adresse from '../commons/adress';
import SwitchKit from '../switchKit/SwitchKit';

const BasicInfoOfCompany = ({ formulaireComplet }) => {
  const classes = useStyles();
  const { errors, values, setFieldValue, submitCount } = useFormikContext();
  const [showSwitchKit, setShowSwitchKit] = useState(false);
  const [rcsLoading, setRcsLoading] = useState(false);
  const { zipcode, city, companyType, kit } = values;
  const timeOut = useRef(null);
  const query = new URLSearchParams(window.location.search);
  const planId = query.get('planId') || '';
  const companyTypeQuery = query.get('type') || query.get('companyType') || '';
  const setRCS = _.debounce(async () => {
    const rcsVille = await fetchRCS(zipcode, city);
    setFieldValue('rcs_ville', rcsVille);
    setRcsLoading(false);
  }, 1000);

  useEffect(() => {
    if (zipcode !== '' && city !== '') {
      setRcsLoading(true);
      clearTimeout(timeOut.current);
      timeOut.current = setTimeout(() => {
        setRCS();
      }, 250);
    }
  }, [zipcode, city]);

  useEffect(() => {
    if (companyType === SCI) setShowSwitchKit(true);
  }, []);

  const confirmOrSwitchKit = (updatedKit) => {
    if (updatedKit.includes('kit4')) {
      setFieldValue('depot_capital_chez', BBF_NOTARIE);
      setFieldValue('connait_depot_des_fonds', YES);
    }
    setFieldValue('kit', updatedKit);
  };

  useEffect(() => {
    if (!planId && !kit) {
      setShowSwitchKit(true);
      return;
    }
    setFieldValue('eurl_sasu', companyTypeQuery || formulaireComplet.eurl_sasu);
    confirmOrSwitchKit(planId || kit);
    setShowSwitchKit(false);
  }, [planId, companyTypeQuery, kit]);

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{getTitle()}</h1>
      <div className={classes.root}>
        <InputWithHelpIcon
          type="string"
          label="Dénomination sociale *"
          name="company_name"
          classes={classes}
        />
        {values.eurl_sasu !== SCI && (
          <Field
            component={TextField}
            type="string"
            label="Dénomination commerciale si différente de la dénomination sociale"
            name="business_name"
            className={classes.nameTextField}
            autoComplete="off"
            variant="outlined"
            size="small"
            inputProps={{ style: { textTransform: 'capitalize' } }}
          />
        )}
        <div className={classes.inputWithHelpIconContainer}>
          <Field
            component={TextField}
            variant="outlined"
            type="string"
            label="Objet social *"
            name="code_ape"
            className={classes.nameTextField}
            style={{ width: '100%' }}
            placeholder=" Inscrire la nature de votre activité, par exemple : conseils, formation,
            travaux de peinture, rénovation de bâtiment, second œuvre, gros-œuvre…"
            multiline
            rows="4"
            autoComplete="off"
            size="small"
            disabled={values.eurl_sasu === SCI}
          />
          <HelpIcon helpName="code_ape" />
        </div>
        <Adresse
          classes={classes}
          title="Adresse du siège social"
          address="address"
          city="city"
          zipCode="zipcode"
          department={null}
          country={null}
          cityLabel="Ville *"
          nationality={null}
          disabledZipCode={true}
          disabledCity={true}
          additionalAddress="additional_address"
        />
        {errors.rcs_ville && !!submitCount && (
          <span style={{ color: 'red', textAlign: 'left' }}>
            Impossible de récupérer la ville du RCS. Merci de vérifier les
            informations suivantes : Ville et Code postal.{' '}
            <b>
              L&rsquo;adresse de la société doit obligatoirement être en France.
            </b>
          </span>
        )}
        {rcsLoading && (
          <span
            style={{
              marginTop: '2%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              color: '#3D319D',
            }}
          >
            <CircularProgress
              style={{ width: '15px', height: '15px', marginRight: '2%' }}
            />
            Recherche de la ville du RCS en cours.
          </span>
        )}
      </div>
      <SwitchKit
        open={showSwitchKit}
        handleClose={() => {
          setShowSwitchKit(false);
        }}
        confirmKit={confirmOrSwitchKit}
        isRecap={false}
        formulaireComplet={formulaireComplet}
      />
    </div>
  );
};

export default BasicInfoOfCompany;

import React from 'react';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  CONCUBIN,
  familySituations,
  genders,
  MARIED,
  OTHER,
  PACTE,
  personTypes,
  PM,
  PP,
  regimeSociale,
} from 'constants/usersContants';
import KeyboardDatePicker from 'components/KeyboardDatePicker';
import { SAS, SASU, SCI } from 'constants/companies';
import _ from 'lodash';
import InfoMoralPerson from './InfoMoralPerson';
import InfoWedding from './InfoWedding';
import SmallRadio from '../commons/smallRadio';
import useStyles from '../style';
import InfoPacte from './InfoPacte';
import Adresse from '../commons/adress';
import InfoCohabitant from './infoCohabitant';

const UserInfo = () => {
  const { values, errors, touched } = useFormikContext();
  const classes = useStyles();
  const {
    personne_physique_morale,
    partner_title,
    partner_family_situation,
    partnerProtectionSocial,
    eurl_sasu,
  } = values;

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Information sur l’(les) associé(e-es)</h1>
      <div className={classes.root}>
        <SmallRadio
          classes={classes}
          title="Type d’associé"
          options={personTypes}
          value={personne_physique_morale}
          name="personne_physique_morale"
          helpName="partner"
        />
        {PM === personne_physique_morale && (
          <InfoMoralPerson classes={classes} values={values} />
        )}
        <SmallRadio
          classes={classes}
          title="Titre"
          options={genders}
          value={partner_title}
          name="partner_title"
        />
        <h2 className={classes.subTitle}>Civilité</h2>
        <Field
          component={TextField}
          type="string"
          label="Nom *"
          name="partner_name"
          className={classes.nameTextField}
          autoComplete="off"
          variant="outlined"
          size="small"
          inputProps={{ style: { textTransform: 'capitalize' } }}
        />
        <Field
          component={TextField}
          type="string"
          label="Prénom *"
          name="partner_firstname"
          className={classes.nameTextField}
          autoComplete="off"
          variant="outlined"
          size="small"
          inputProps={{ style: { textTransform: 'capitalize' } }}
        />
        <Field
          component={TextField}
          type="email"
          label="Email *"
          name="partner_email"
          className={classes.nameTextField}
          variant="outlined"
          size="small"
        />
        {PP === personne_physique_morale &&
          eurl_sasu !== SAS &&
          eurl_sasu !== SCI &&
          eurl_sasu !== SASU && (
            <>
              <h2 className={classes.subTitle}>Protection sociale</h2>
              <Field
                component={TextField}
                type="string"
                label="N° sécurité sociale *"
                name="partnerSecuNumber"
                className={classes.nameTextField}
                autoComplete="off"
                variant="outlined"
                size="small"
              />
              <SmallRadio
                classes={classes}
                title="Régime actuel de protection sociale *"
                options={regimeSociale}
                value={partnerProtectionSocial}
                name="partnerProtectionSocial"
                style={{ marginBottom: '3%' }}
              />
              {partnerProtectionSocial === OTHER && (
                <Field
                  component={TextField}
                  type="string"
                  label="Préciser *"
                  name="partnerProtectionSocialDetail"
                  className={classes.nameTextField}
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                />
              )}
            </>
          )}

        {/* show N° sécurité sociale * in case eurl_sasu === SASU or eurl_sasu === SAS */}
        {PP === personne_physique_morale &&
          (eurl_sasu === SASU || eurl_sasu === SAS) && (
            <>
              <h2 className={classes.subTitle}>Protection sociale</h2>
              <Field
                component={TextField}
                type="string"
                label="N° sécurité sociale *"
                name="partnerSecuNumber"
                className={classes.nameTextField}
                autoComplete="off"
                variant="outlined"
                size="small"
              />
            </>
          )}
        <h2 className={classes.subTitle}>Informations de naissance</h2>
        <Field
          component={KeyboardDatePicker}
          clearable
          label="Date de naissance *"
          placeholder="01/01/1990"
          format="dd/MM/yyyy"
          maxDate={new Date()}
          name="partner_date_of_birth"
          className={classes.nameTextField}
          inputVariant="outlined"
          size="small"
        />
        <Adresse
          classes={classes}
          address={null}
          city="partner_place_of_birth"
          zipCode="partner_place_of_birth_zipcode"
          department="partner_state_of_birth"
          country="partner_country_of_birth"
          cityLabel="Ville de naissance *"
          countryLabel="Pays de naissance *"
          nationality="partner_nationality"
        />
        <Adresse
          classes={classes}
          title="Adresse *"
          address="partner_place_of_living"
          city="partner_city"
          zipCode="partner_zipcode"
          department={null}
          country={null}
          cityLabel="Ville *"
          nationality={null}
          disabledZipCode={true}
          disabledCity={true}
          additionalAddress="partner_additional_address"
        />
        <h2 className={classes.subTitle}>Votre filiation</h2>
        <Field
          component={TextField}
          type="string"
          label="Nom du père *"
          name="partner_father_name"
          className={classes.nameTextField}
          autoComplete="off"
          variant="outlined"
          size="small"
          inputProps={{ style: { textTransform: 'capitalize' } }}
        />
        <Field
          component={TextField}
          type="string"
          label="Prénom du père *"
          name="partner_father_firstname"
          className={classes.nameTextField}
          autoComplete="off"
          variant="outlined"
          size="small"
          inputProps={{ style: { textTransform: 'capitalize' } }}
        />
        <Field
          component={TextField}
          type="string"
          label="Nom de jeune fille de la mère *"
          name="partner_mother_name"
          className={classes.nameTextField}
          autoComplete="off"
          variant="outlined"
          size="small"
          inputProps={{ style: { textTransform: 'capitalize' } }}
        />
        <Field
          component={TextField}
          type="string"
          label="Prénom de la mère *"
          name="partner_mother_firstname"
          className={classes.nameTextField}
          autoComplete="off"
          variant="outlined"
          size="small"
          inputProps={{ style: { textTransform: 'capitalize' } }}
        />
        {PP === personne_physique_morale && (
          <SmallRadio
            classes={classes}
            title="Situation familiale *"
            options={familySituations}
            value={partner_family_situation}
            name="partner_family_situation"
          />
        )}
        {PP === personne_physique_morale &&
          partner_family_situation === MARIED && (
            <InfoWedding classes={classes} />
          )}
        {PP === personne_physique_morale &&
          partner_family_situation === PACTE && (
            <InfoPacte classes={classes} eurl_sasu={eurl_sasu} />
          )}
        {PP === personne_physique_morale &&
          partner_family_situation === CONCUBIN && (
            <InfoCohabitant classes={classes} />
          )}
      </div>
      {!_.isEmpty(errors) && touched.partner_family_situation && (
        <span
          style={{
            color: 'red',
            display: 'block',
            marginTop: '5%',
            width: '100%',
            textAlign: 'right',
          }}
        >
          Merci de compléter toutes les informations.
        </span>
      )}
    </div>
  );
};

export default UserInfo;

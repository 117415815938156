import * as Yup from 'yup';
import { format, addYears, endOfYear } from 'date-fns';
import { debutActivities, REQUIRED } from 'constants/usersContants';

const validationSchema = () => {
  return Yup.object().shape({
    closing_date_with_year: Yup.date().when(
      'date_debut_activite',
      (date_debut_activite) => {
        if (date_debut_activite !== '') {
          return Yup.date()
            .required('Required')
            .min(
              format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
              'la date de cloture du premier exercice doit être postérieure à la date de signature des statuts'
            )
            .max(
              format(
                new Date(endOfYear(new Date(addYears(new Date(), 1)))),
                "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
              ),

              "la date de cloture doit être avant le 31/12 de l'année prochaine"
            )
            .required(REQUIRED)
            .nullable()
            .typeError(REQUIRED);
        }
        return Yup.date();
      }
    ),
    date_debut_activite: Yup.date()
      .when('type_depart_activite', {
        is: (type_depart_activite) =>
          type_depart_activite === debutActivities[1].value,
        then: Yup.date().required(REQUIRED).typeError(REQUIRED).nullable(),
      })
      .nullable(),
  });
};

export default validationSchema;

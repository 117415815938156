import * as Yup from 'yup';
import { format } from 'date-fns';
import {
  YES,
  REQUIRED,
  DATE_CONSTRAINTE,
  DATE_RIQUIRED,
} from 'constants/usersContants';

const validationSchema = () => {
  return Yup.object().shape({
    cash_funds_numbers: Yup.number()
      .required(REQUIRED)
      .min(1, 'Les apports en numéraire doivent être supérieur à 0.'),
    apport_nature: Yup.string().required(REQUIRED),
    apport_fonds_de_commerce: Yup.string().required(REQUIRED),
    date_apport_fonds_de_commerce: Yup.date()
      .when(['apport_nature', 'apport_fonds_de_commerce'], {
        is: (apport_nature, apport_fonds_de_commerce) =>
          apport_nature === YES && apport_fonds_de_commerce === YES,
        then: Yup.date()
          .max(
            format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
            DATE_CONSTRAINTE
          )
          .required(DATE_RIQUIRED)
          .typeError(DATE_RIQUIRED),
      })
      .nullable(),
    valeur_apport_fonds_de_commerce: Yup.number().when(
      ['apport_nature', 'apport_fonds_de_commerce'],
      {
        is: (apport_nature, apport_fonds_de_commerce) =>
          apport_nature === YES && apport_fonds_de_commerce === YES,
        then: Yup.number().required(REQUIRED),
      }
    ),

    apport_titres_societe: Yup.string().required(REQUIRED),
    date_apport_titres_societe: Yup.date()
      .when(['apport_nature', 'apport_titres_societe'], {
        is: (apport_nature, apport_titres_societe) =>
          apport_nature === YES && apport_titres_societe === YES,
        then: Yup.date()
          .max(
            format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
            DATE_CONSTRAINTE
          )
          .required(DATE_RIQUIRED)
          .typeError(DATE_RIQUIRED),
      })
      .nullable(),
    valeur_apport_titres_societe: Yup.number().when(
      ['apport_nature', 'apport_titres_societe'],
      {
        is: (apport_nature, apport_titres_societe) =>
          apport_nature === YES && apport_titres_societe === YES,
        then: Yup.number().required(REQUIRED),
      }
    ),
    raison_sociale_societe_apport_titres_societe: Yup.string().when(
      ['apport_nature', 'apport_titres_societe'],
      {
        is: (apport_nature, apport_titres_societe) =>
          apport_nature === YES && apport_titres_societe === YES,
        then: Yup.string().required(REQUIRED),
      }
    ),
    nombre_titres_apportes_apport_titres_societe: Yup.number().when(
      'apport_titres_societe',
      {
        is: (apport_titres_societe) => apport_titres_societe === YES,
        then: Yup.number().required(REQUIRED),
      }
    ),
    nombre_titres_total_apport_titres_societe: Yup.number().when(
      'apport_titres_societe',
      {
        is: (apport_titres_societe) => apport_titres_societe === YES,
        then: Yup.number().required(REQUIRED),
      }
    ),
    apport_nature_divers: Yup.string().required(REQUIRED),
    date_apport_nature_divers: Yup.date()
      .when(['apport_nature', 'apport_nature_divers'], {
        is: (apport_nature, apport_nature_divers) =>
          apport_nature === YES && apport_nature_divers === YES,
        then: Yup.date()
          .max(
            format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
            DATE_CONSTRAINTE
          )
          .required(DATE_RIQUIRED)
          .typeError(DATE_RIQUIRED),
      })
      .nullable(),
    valeur_apport_nature_divers: Yup.number().when(
      ['apport_nature', 'apport_nature_divers'],
      {
        is: (apport_nature, apport_titres_societe) =>
          apport_nature === YES && apport_titres_societe === YES,
        then: Yup.number().required(REQUIRED),
      }
    ),
  });
};

//  eslint-disable-next-line
export { validationSchema };

import {
  CONCUBIN,
  MARIED,
  PACTE,
  PARTNER,
  REQUIRED,
  TIERS,
  YES,
} from 'constants/usersContants';
import * as Yup from 'yup';

const validationSchema = () => {
  return Yup.object().shape({
    director_type: Yup.string().required(REQUIRED),
    associe_gerant_multiassocie: Yup.array().when('director_type', {
      is: (director_type) => director_type === PARTNER,
      then: Yup.array()
        .required(REQUIRED)
        .min(
          1,
          'Vous devez sélectionner un gérant parmi les associés(e)s ou remplir les informations de la personne tiers.'
        ),
    }),
    director_title: Yup.string().when('director_type', {
      is: (director_type) => director_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    director_name: Yup.string().when('director_type', {
      is: (director_type) => director_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    director_firstname: Yup.string().when('director_type', {
      is: (director_type) => director_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    director_father_name: Yup.string().when('director_type', {
      is: (director_type) => director_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    director_father_firstname: Yup.string().when('director_type', {
      is: (director_type) => director_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    director_mother_name: Yup.string().when('director_type', {
      is: (director_type) => director_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    director_mother_firstname: Yup.string().when('director_type', {
      is: (director_type) => director_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    director_date_of_birth: Yup.date()
      .when('director_type', {
        is: (director_type) => director_type === TIERS,
        then: Yup.date()
          .required(REQUIRED)
          .typeError('La date de naissance est necessaire'),
      })
      .nullable(),
    director_place_of_birth: Yup.string().when('director_type', {
      is: (director_type) => director_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    director_place_of_birth_zipCode: Yup.string().when('director_type', {
      is: (director_type) => director_type === TIERS,
      then: Yup.string().required(REQUIRED),
      otherwise: Yup.string().nullable(true),
    }),
    director_state_of_birth: Yup.string().when('director_type', {
      is: (director_type) => director_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    director_country_of_birth: Yup.string().when('director_type', {
      is: (director_type) => director_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    director_nationality: Yup.string().when('director_type', {
      is: (director_type) => director_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    director_place_of_living: Yup.string().when('director_type', {
      is: (director_type) => director_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    director_zipcode: Yup.string().when('director_type', {
      is: (director_type) => director_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    director_city: Yup.string().when('director_type', {
      is: (director_type) => director_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    director_email: Yup.string().when('director_type', {
      is: (director_type) => director_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    director_secu_number: Yup.string().when('director_type', {
      is: (director_type) => director_type === TIERS,
      then: Yup.string()
        .required(REQUIRED)
        .nullable(true)
        .matches(
          /^[0-9]{15}$/,
          'Le numéro de sécurité sociale doit contenir 15 chiffres incluant la clé.'
        ),
    }),
    duree_gerance_determinee: Yup.string().when('director_type', {
      is: (director_type) => director_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),

    duree_de_gerance: Yup.number().when(
      'duree_gerance_determinee',
      (duree_gerance_determinee) => {
        if (duree_gerance_determinee === YES) {
          return Yup.number().required(REQUIRED).min(1);
        }
        return Yup.number();
      }
    ),

    // validation for "Situation familiale"
    director_family_situation: Yup.string().when('director_type', {
      is: (director_type) => director_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    // if has married
    director_wedding_prenup_type: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === MARIED && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_spouce_title: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === MARIED && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_spouce_name: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === MARIED && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_spouce_firstname: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === MARIED && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_spouce_email: Yup.string()
      .email(`Saisissez une adresse e-mail valide.`)
      .when(['director_family_situation', 'director_type'], {
        is: (director_family_situation, director_type) =>
          director_family_situation === MARIED && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }),
    director_spouce_birthday: Yup.date()
      .nullable()
      .when(['director_family_situation', 'director_type'], {
        is: (director_family_situation, director_type) =>
          director_family_situation === MARIED && director_type === TIERS,
        then: Yup.date().required(REQUIRED).typeError('Date est obligatoire'),
      })
      .nullable(),

    director_spouce_place_of_birth: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === MARIED && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_spouce_adress: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === MARIED && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_spouce_city: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === MARIED && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_spouce_zipcode: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === MARIED && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_spouce_effective_date: Yup.date()
      .nullable()
      .when(['director_family_situation', 'director_type'], {
        is: (director_family_situation, director_type) =>
          director_family_situation === MARIED && director_type === TIERS,
        then: Yup.date().required(REQUIRED).typeError('Date est obligatoire'),
      })
      .nullable(),
    director_spouce_position: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === MARIED && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_spouce_secu_number: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === MARIED && director_type === TIERS,
        then: Yup.string()
          .required(REQUIRED)
          .nullable(true)
          .matches(
            /^[0-9]{15}$/,
            'Le numéro de sécurité sociale doit contenir 15 chiffres incluant la clé.'
          ),
      }
    ),
    director_wedding_prenup: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === MARIED && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),

    director_wedding_prenup_officer_office_address: Yup.string().when(
      ['director_wedding_prenup'],
      {
        is: (director_wedding_prenup) => director_wedding_prenup === YES,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_wedding_prenup_officer_office_city: Yup.string().when(
      ['director_wedding_prenup'],
      {
        is: (director_wedding_prenup) => director_wedding_prenup === YES,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_wedding_prenup_officer_office_zipcode: Yup.string().when(
      ['director_wedding_prenup'],
      {
        is: (director_wedding_prenup) => director_wedding_prenup === YES,
        then: Yup.string().required(REQUIRED),
      }
    ),

    // if has pacse
    director_pacs_type: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === PACTE && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_pacse_title: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === PACTE && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_pacse_name: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === PACTE && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_pacse_firstname: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === PACTE && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_pacse_email: Yup.string()
      .email(`Saisissez une adresse e-mail valide.`)
      .when(['director_family_situation', 'director_type'], {
        is: (director_family_situation, director_type) =>
          director_family_situation === PACTE && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }),
    director_pacse_birthday: Yup.date()
      .nullable()
      .when(['director_family_situation', 'director_type'], {
        is: (director_family_situation, director_type) =>
          director_family_situation === PACTE && director_type === TIERS,
        then: Yup.date().required(REQUIRED).typeError('Date est obligatoire'),
      })
      .nullable(),

    director_pacse_place_of_birth: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === PACTE && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_pacse_adress: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === PACTE && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_pacse_city: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === PACTE && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_pacse_zipcode: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === PACTE && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_pacse_effective_date: Yup.date()
      .nullable()
      .when(['director_family_situation', 'director_type'], {
        is: (director_family_situation, director_type) =>
          director_family_situation === PACTE && director_type === TIERS,
        then: Yup.date().required(REQUIRED).typeError('Date est obligatoire'),
      })
      .nullable(),
    director_pacse_position: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === PACTE && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_pacse_secu_number: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === PACTE && director_type === TIERS,
        then: Yup.string()
          .required(REQUIRED)
          .nullable(true)
          .matches(
            /^[0-9]{15}$/,
            'Le numéro de sécurité sociale doit contenir 15 chiffres incluant la clé.'
          ),
      }
    ),
    // if has concubin
    director_cohabitant_title: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === CONCUBIN && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_cohabitant_name: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === CONCUBIN && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_cohabitant_firstname: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === CONCUBIN && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_cohabitant_email: Yup.string()
      .email(`Saisissez une adresse e-mail valide.`)
      .when(['director_family_situation', 'director_type'], {
        is: (director_family_situation, director_type) =>
          director_family_situation === CONCUBIN && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }),
    director_cohabitant_birthday: Yup.date()
      .nullable()
      .when(['director_family_situation', 'director_type'], {
        is: (director_family_situation, director_type) =>
          director_family_situation === CONCUBIN && director_type === TIERS,
        then: Yup.date().required(REQUIRED).typeError('Date est obligatoire'),
      })
      .nullable(),

    director_cohabitant_place_of_birth: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === CONCUBIN && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_cohabitant_adress: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === CONCUBIN && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_cohabitant_city: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === CONCUBIN && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_cohabitant_zipcode: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === CONCUBIN && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_cohabitant_effective_date: Yup.date()
      .nullable()
      .when(['director_family_situation', 'director_type'], {
        is: (director_family_situation, director_type) =>
          director_family_situation === CONCUBIN && director_type === TIERS,
        then: Yup.date().required(REQUIRED).typeError('Date est obligatoire'),
      })
      .nullable(),
    director_cohabitant_position: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === CONCUBIN && director_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    director_cohabitant_secu_number: Yup.string().when(
      ['director_family_situation', 'director_type'],
      {
        is: (director_family_situation, director_type) =>
          director_family_situation === CONCUBIN && director_type === TIERS,
        then: Yup.string()
          .required(REQUIRED)
          .nullable(true)
          .matches(
            /^[0-9]{15}$/,
            'Le numéro de sécurité sociale doit contenir 15 chiffres incluant la clé.'
          ),
      }
    ),
  });
};

//  eslint-disable-next-line
export { validationSchema };

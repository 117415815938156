import * as Yup from 'yup';
import { format } from 'date-fns';
import {
  YES,
  REQUIRED,
  DATE_CONSTRAINTE,
  DATE_RIQUIRED,
} from 'constants/usersContants';

const validationSchema = () => {
  return Yup.object().shape({
    partner_apport_numeraire: Yup.string().required(REQUIRED),
    partnerTotalApport: Yup.number().required(REQUIRED).min(1),
    partner_cash_funds_numbers: Yup.number().when('partner_apport_numeraire', {
      is: (partner_apport_numeraire) => partner_apport_numeraire === YES,
      then: Yup.number().required('REQUIRED'),
    }),

    partner_apport_nature: Yup.string().required(REQUIRED),
    partner_apport_fonds_de_commerce: Yup.string().required(REQUIRED),
    partner_date_apport_fonds_de_commerce: Yup.date()
      .when('partner_apport_fonds_de_commerce', {
        is: (partner_apport_fonds_de_commerce) =>
          partner_apport_fonds_de_commerce === YES,
        then: Yup.date()
          .max(
            format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
            DATE_CONSTRAINTE
          )
          .required(DATE_RIQUIRED),
      })
      .nullable(),
    partner_valeur_apport_fonds_de_commerce: Yup.number().when(
      'partner_apport_fonds_de_commerce',
      {
        is: (partner_apport_fonds_de_commerce) =>
          partner_apport_fonds_de_commerce === YES,
        then: Yup.number().required('REQUIRED'),
      }
    ),

    partner_apport_titres_societe: Yup.string().required(REQUIRED),
    partner_date_apport_titres_societe: Yup.date()
      .when(['partner_apport_nature', 'partner_apport_titres_societe'], {
        is: (partner_apport_nature, partner_apport_titres_societe) =>
          partner_apport_nature === YES &&
          partner_apport_titres_societe === YES,
        then: Yup.date()
          .max(
            format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
            DATE_CONSTRAINTE
          )
          .required(DATE_RIQUIRED)
          .typeError(DATE_RIQUIRED),
      })
      .nullable(),
    partner_valeur_apport_titres_societe: Yup.number().when(
      ['partner_apport_nature', 'partner_apport_titres_societe'],
      {
        is: (partner_apport_nature, partner_apport_titres_societe) =>
          partner_apport_nature === YES &&
          partner_apport_titres_societe === YES,
        then: Yup.number().required(REQUIRED),
      }
    ),
    partner_raison_sociale_societe_apport_titres_societe: Yup.string().when(
      ['partner_apport_nature', 'partner_apport_titres_societe'],
      {
        is: (partner_apport_nature, partner_apport_titres_societe) =>
          partner_apport_nature === YES &&
          partner_apport_titres_societe === YES,
        then: Yup.string().required('REQUIRED'),
      }
    ),
    partner_nombre_titres_apportes_apport_titres_societe: Yup.number().when(
      ['partner_apport_nature', 'partner_apport_titres_societe'],
      {
        is: (partner_apport_nature, partner_apport_titres_societe) =>
          partner_apport_nature === YES &&
          partner_apport_titres_societe === YES,
        then: Yup.number().required('REQUIRED'),
      }
    ),
    partner_nombre_titres_total_apport_titres_societe: Yup.number().when(
      ['partner_apport_nature', 'partner_apport_titres_societe'],
      {
        is: (partner_apport_nature, partner_apport_titres_societe) =>
          partner_apport_nature === YES &&
          partner_apport_titres_societe === YES,
        then: Yup.number().required('REQUIRED'),
      }
    ),

    partner_apport_nature_divers: Yup.string().required(REQUIRED),
    partner_date_apport_nature_divers: Yup.date()
      .when(['partner_apport_nature', 'partner_apport_nature_divers'], {
        is: (partner_apport_nature, partner_apport_titres_societe) =>
          partner_apport_nature === YES &&
          partner_apport_titres_societe === YES,
        then: Yup.date()
          .max(
            format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
            DATE_CONSTRAINTE
          )
          .required(DATE_RIQUIRED)
          .typeError(DATE_RIQUIRED),
      })
      .nullable(),
    partner_valeur_apport_nature_divers: Yup.number()
      .when(['partner_apport_nature', 'partner_apport_nature_divers'], {
        is: (partner_apport_nature, partner_apport_titres_societe) =>
          partner_apport_nature === YES &&
          partner_apport_titres_societe === YES,
        then: Yup.number().required(REQUIRED),
      })
      .nullable(),
  });
};

//  eslint-disable-next-line
export { validationSchema };

import React from 'react';
import { add, sub } from 'date-fns';
import { useFormikContext, Field } from 'formik';
import { debutActivities } from 'constants/usersContants';
import KeyboardDatePicker from 'components/KeyboardDatePicker';
import HelpIcon from 'components/commons/HelpIcon';
import { SCI } from 'constants/companies';
import { Divider } from '@material-ui/core';
import useStyles from '../style';
import SmallRadio from '../commons/smallRadio';

function CompanyDate() {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const { type_depart_activite, eurl_sasu } = values;

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Les dates de la société</h1>
      <div className={classes.root}>
        <div className={classes.inputWithHelpIconContainer}>
          <h2 className={classes.subTitle}>Date de clôture de 1er exercice</h2>
          <div style={{ alignSelf: 'flex-end' }}>
            <HelpIcon helpName="date" />
          </div>
        </div>
        {eurl_sasu === SCI && (
          <h2 className={classes.subTitle}>
            Si votre SCI est à l’IR, la date de clôture est nécessairement le
            31/12, si vous optez pour l’IS, la date de clôture peut-être
            différente mais nous ne vous le conseillons pas.
          </h2>
        )}
        {eurl_sasu !== SCI && (
          <SmallRadio
            classes={classes}
            title="Date de début d'activité"
            options={debutActivities}
            value={type_depart_activite}
            name="type_depart_activite"
            onChangeRadio={() => {
              setFieldValue('date_debut_activite', null);
              setFieldValue('closing_date_with_year', null);
            }}
          />
        )}
        {eurl_sasu !== SCI &&
          type_depart_activite === debutActivities[0].value && (
            <h2 className={classes.subTitle}>
              (Immédiatement en date de signature des statuts)
            </h2>
          )}
        {type_depart_activite === debutActivities[1].value && (
          <>
            <h2 className={classes.subTitle}>
              Dans les 30 jours après la signature des statuts
            </h2>
            <Field
              component={KeyboardDatePicker}
              clearable
              label="Date de début d'activité *"
              placeholder="01/01/1990"
              format="dd/MM/yyyy"
              name="date_debut_activite"
              className={classes.nameTextField}
              inputVariant="outlined"
              maxDate={
                values.closing_date_with_year
                  ? sub(new Date(values.closing_date_with_year), { days: 1 })
                  : null
              }
              size="small"
              onChange={() => {
                setFieldValue('closing_date_with_year', null);
              }}
            />
          </>
        )}
        {type_depart_activite === 'sans_activite' && (
          <h2 className={classes.subTitle}>
            Si vous ne démarrez pas votre activité immédiatement ou dans le mois
            de la signature des statuts, votre société est immatriculée sans
            activité. La création de votre société engendrera un surcout de
            l’ordre 120 € (frais de greffe) et la mise en activité entrainera un
            traitement administratif et des frais supplémentaires ultérieur de
            l’ordre de 350 € (frais de greffe et nos honoraires).
          </h2>
        )}
        <Divider className={classes.divider} />
        <Field
          component={KeyboardDatePicker}
          clearable
          label="Date de clôture du 1er exercice *"
          placeholder="01/01/1990"
          format="dd/MM/yyyy"
          minDate={
            values.date_debut_activite
              ? add(new Date(values.date_debut_activite), { days: 1 })
              : new Date()
          }
          name="closing_date_with_year"
          className={classes.nameTextField}
          inputVariant="outlined"
          size="small"
        />
      </div>
    </div>
  );
}

export default CompanyDate;

import React, { useEffect } from 'react';
import { YES, yesNo } from 'constants/usersContants';
import { TextField } from 'formik-material-ui';
import { Field, useFormikContext } from 'formik';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import HelpWithTitle from 'components/commons/HelpWithTitle';
import HelpIcon from 'components/commons/HelpIcon';
import { SCI } from 'constants/companies';
import useStyles from '../style';
import SmallRadio from '../commons/smallRadio';

const PartnerContribution = () => {
  const classes = useStyles();
  const { values, errors, touched, setFieldValue } = useFormikContext();
  const {
    eurl_sasu,
    partner_apport_nature,
    partner_apport_fonds_de_commerce,
    partner_apport_titres_societe,
    partner_apport_nature_divers,
    partner_apport_industrie,
    partner_cash_funds_numbers,
    partner_valeur_apport_fonds_de_commerce,
    partner_valeur_apport_titres_societe,
    partner_valeur_apport_nature_divers,
    partner_expertise_funds_numbers,
  } = values;

  useEffect(() => {
    const totalApport =
      partner_cash_funds_numbers +
      partner_valeur_apport_fonds_de_commerce +
      partner_valeur_apport_titres_societe +
      partner_valeur_apport_nature_divers +
      partner_expertise_funds_numbers;
    setFieldValue('partnerTotalApport', totalApport);
  }, [
    partner_cash_funds_numbers,
    partner_valeur_apport_fonds_de_commerce,
    partner_valeur_apport_titres_societe,
    partner_valeur_apport_nature_divers,
    partner_expertise_funds_numbers,
  ]);

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>
        Les apports à la société en création par l’associé(e)
      </h1>
      <div className={classes.root}>
        <HelpWithTitle
          title="Aide sur les apports de la société."
          helpName="contribution"
        />
        <div className={classes.inputWithHelpIconContainer}>
          <h2 className={classes.subTitle}>Apports en numéraire</h2>
          <div style={{ alignSelf: 'flex-end' }}>
            <HelpIcon helpName="numeraire" />
          </div>
        </div>
        <Field
          width="100%"
          component={TextField}
          type="number"
          label="Apports en numéraire - montant en chiffres *"
          name="partner_cash_funds_numbers"
          className={classes.nameTextField}
          autoComplete="off"
          variant="outlined"
          size="small"
        />
        <SmallRadio
          classes={classes}
          title="Y a-t-il un apport en nature ?"
          options={yesNo}
          value={partner_apport_nature}
          name="partner_apport_nature"
          style={{ marginBottom: '4%' }}
          helpName="nature"
        />
        {partner_apport_nature === YES && (
          <div className={classes.subCategories}>
            {eurl_sasu !== SCI && (
              <>
                <SmallRadio
                  classes={classes}
                  title="Y a-t-il un apport de fonds de commerce ?"
                  options={yesNo}
                  value={partner_apport_fonds_de_commerce}
                  name="partner_apport_fonds_de_commerce"
                  style={{ marginBottom: '4%' }}
                />
                {partner_apport_fonds_de_commerce === YES && (
                  <>
                    <Field
                      component={KeyboardDatePicker}
                      clearable
                      label="Date de l'apport"
                      placeholder="01/01/1990"
                      format="dd/MM/yyyy"
                      maxDate={new Date()}
                      name="partner_date_apport_fonds_de_commerce"
                      className={classes.nameTextField}
                      inputVariant="outlined"
                      size="small"
                    />

                    <Field
                      component={TextField}
                      type="number"
                      label="Evaluation de l'apport - montant en chiffres"
                      name="partner_valeur_apport_fonds_de_commerce"
                      className={classes.nameTextField}
                      autoComplete="off"
                      variant="outlined"
                      size="small"
                    />
                  </>
                )}
              </>
            )}
            <SmallRadio
              classes={classes}
              title="Un apport de titres de société ?"
              options={yesNo}
              value={partner_apport_titres_societe}
              name="partner_apport_titres_societe"
              style={{ marginBottom: '4%' }}
            />
            {partner_apport_titres_societe === YES && (
              <>
                <Field
                  component={KeyboardDatePicker}
                  clearable
                  label="Date de l'apport *"
                  // placeholder="01/01/1990"
                  format="dd/MM/yyyy"
                  maxDate={new Date()}
                  name="partner_date_apport_titres_societe"
                  className={classes.nameTextField}
                  inputVariant="outlined"
                  size="small"
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="number"
                  label="Evaluation de l'apport - montant en chiffres *"
                  name="partner_valeur_apport_titres_societe"
                  className={classes.nameTextField}
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Raison sociale de la société apportée  *"
                  name="partner_raison_sociale_societe_apport_titres_societe"
                  className={classes.nameTextField}
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="number"
                  label="Nombre de titres apportés *"
                  name="partner_nombre_titres_apportes_apport_titres_societe"
                  className={classes.nameTextField}
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="number"
                  label="Nombre de titres total composant le capital de la société apportée *"
                  name="partner_nombre_titres_total_apport_titres_societe"
                  className={classes.nameTextField}
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                />
              </>
            )}
            <SmallRadio
              classes={classes}
              title="Y a-t-il des apports en nature de véhicule, matériel informatique, site internet etc...?"
              options={yesNo}
              value={partner_apport_nature_divers}
              name="partner_apport_nature_divers"
              style={{ marginBottom: '4%' }}
            />
            {partner_apport_nature_divers === YES && (
              <>
                <Field
                  component={KeyboardDatePicker}
                  clearable
                  label="Date de l'apport *"
                  placeholder="01/01/1990"
                  format="dd/MM/yyyy"
                  maxDate={new Date()}
                  name="partner_date_apport_nature_divers"
                  className={classes.nameTextField}
                  inputVariant="outlined"
                  size="small"
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="number"
                  label="Evaluation de l'apport en nature divers - montant en chiffres *"
                  name="partner_valeur_apport_nature_divers"
                  className={classes.nameTextField}
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Veuillez décrire les biens apportés"
                  name="partner_description_biens_apport_nature_divers"
                  className={classes.nameTextField}
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  multiline
                  rows="4"
                />
              </>
            )}
          </div>
        )}
        {eurl_sasu !== SCI && (
          <>
            <SmallRadio
              classes={classes}
              title="Y a-t-il des apports en industrie ?"
              options={yesNo}
              value={partner_apport_industrie}
              name="partner_apport_industrie"
              style={{ marginBottom: '4%' }}
              helpName="industrie"
            />
            {partner_apport_industrie === YES && (
              <>
                <Field
                  component={TextField}
                  type="number"
                  label="Apports en industrie - montant en chiffres "
                  name="partner_expertise_funds_numbers"
                  className={classes.nameTextField}
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                />
              </>
            )}
          </>
        )}
      </div>
      {touched.partner_apport_numeraire && errors.partnerTotalApport && (
        <div style={{ color: 'red', textAlign: 'left' }}>
          {`L'apport de l'associé(e) doit être supérieur à zéro.`}
        </div>
      )}
    </div>
  );
};

export default PartnerContribution;

import React from 'react';
import { genders } from 'constants/usersContants';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import { Field, useFormikContext } from 'formik';
import QuestionMark from 'components/commons/QuestionMark';
import getUserInfoPositionOptions from 'helpers/userInfo';
import { TextField } from 'formik-material-ui';
import { Typography } from '@material-ui/core';
import SmallRadio from '../commons/smallRadio';
import Adresse from '../commons/adress';

const InfoCohabitantCEO = ({ classes, eurl_sasu }) => {
  const {
    values: { director_cohabitant_title, director_cohabitant_position },
  } = useFormikContext();
  return (
    <>
      <h2 className={classes.subTitle}>Informations sur votre conjoint.</h2>
      <SmallRadio
        classes={classes}
        options={genders}
        value={director_cohabitant_title}
        name="director_cohabitant_title"
        style={{ marginBottom: '2%' }}
      />
      <Field
        component={TextField}
        type="string"
        label="Nom du conjoint *"
        name="director_cohabitant_name"
        className={classes.nameTextField}
        variant="outlined"
        size="small"
        inputProps={{ style: { textTransform: 'capitalize' } }}
      />
      <Field
        component={TextField}
        type="string"
        label="Prénom du conjoint *"
        name="director_cohabitant_firstname"
        className={classes.nameTextField}
        variant="outlined"
        size="small"
        inputProps={{ style: { textTransform: 'capitalize' } }}
      />
      <Field
        component={TextField}
        type="string"
        label="Adresse courriel *"
        name="director_cohabitant_email"
        className={classes.nameTextField}
        variant="outlined"
        size="small"
      />
      <Field
        component={TextField}
        type="string"
        label="N° sécurité sociale *"
        name="director_cohabitant_secu_number"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      />
      <Field
        component={KeyboardDatePicker}
        clearable
        label="Date de naissance du conjoint *"
        placeholder="01/01/1990"
        format="dd/MM/yyyy"
        maxDate={new Date()}
        name="director_cohabitant_birthday"
        className={classes.nameTextField}
        inputVariant="outlined"
        size="small"
      />
      <Field
        component={TextField}
        type="string"
        label="Lieu de naissance du conjoint *"
        name="director_cohabitant_place_of_birth"
        className={classes.nameTextField}
        variant="outlined"
        size="small"
      />
      <Adresse
        classes={classes}
        title="Adresse *"
        address="director_cohabitant_adress"
        city="director_cohabitant_city"
        zipCode="director_cohabitant_zipcode"
        department={null}
        country={null}
        cityLabel="Ville *"
        nationality={null}
        disabledZipCode={true}
        disabledCity={true}
        additionalAddress="director_cohabitant_additional_adress"
      />
      <h2 className={classes.subTitle}>
        {`Statut au sein de l'entreprise *`}
        <QuestionMark
          classes={classes}
          content={
            <>
              <Typography>
                <b>Salarié</b>
                {`: statut pouvant être choisi par le conjoint qui
        exerce une activité professionnelle régulière dans l’entreprise en
        percevant un salaire. Comme pour tout salarié, une déclaration
        préalable à l’embauche doit avoir été effectuée auprès de
        l’organisme social compétent pour l’entreprise`}
              </Typography>
              <br />
              <Typography>
                <b>Associé (si société)</b>
                {`: statut pouvant être choisi par le
          conjoint du dirigeant d'une société qui exerce une activité
          professionnelle régulière dans l'entreprise et détient des parts
          sociales dans la société. Les formalités ont été réalisées au
          niveau de la société`}
              </Typography>
              <br />
              <Typography>
                <b>Collaborateur</b>
                {` : statut pouvant être choisi par le conjoint d’un entrepreneur individuel, du 
          gérant associé unique d’une SARL unipersonnelle (EURL), ou du gérant associé majoritaire d’une 
          SARL ou SELARL, qui exerce une activité professionnelle régulière dans l’entreprise sans être 
          rémunéré, et sans être associé. Le conjoint collaborateur ne perçoit pas de rémunération et n’a 
          pas de contrat de travail. Ce statut est limité à une durée totale de 5 ans.`}
              </Typography>
            </>
          }
        />
      </h2>
      <Field
        component={KeyboardDatePicker}
        clearable
        label="Date d’effet *"
        placeholder="01/01/1990"
        format="dd/MM/yyyy"
        maxDate={new Date()}
        name="director_cohabitant_effective_date"
        className={classes.nameTextField}
        inputVariant="outlined"
        size="small"
      />
      <SmallRadio
        classes={classes}
        options={getUserInfoPositionOptions(eurl_sasu)}
        value={director_cohabitant_position}
        name="director_cohabitant_position"
        style={{ marginBottom: '2%' }}
      />
    </>
  );
};

export default InfoCohabitantCEO;

import { SCI } from 'constants/companies';

const initialValues = (values) => {
  return {
    kit: values.kit,
    eurl_sasu: values.eurl_sasu,
    company_name: values.company_name || '',
    business_name: values.business_name || '',
    companyType: values.companyType,
    code_ape:
      values.eurl_sasu === SCI
        ? `La Société a pour objet l'acquisition, la prise à bail, la gestion, la location et l'administration de tous biens mobiliers et immobiliers et toutes opérations financières, mobilières ou immobilières se rattachant directement ou indirectement à cet objet et susceptibles d'en favoriser la réalisation, à condition toutefois d'en respecter le caractère civil.`
        : values.code_ape || '',
    address: values.address || '',
    additional_address: values.additional_address || '',
    city: values.city || '',
    zipcode: values.zipcode || '',
    rcs_ville: values.rcs_ville || '',
  };
};

// eslint-disable-next-line
export { initialValues };
